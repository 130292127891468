<template>
  <div class="playerQueue is-flex is-flex-direction-column is-justify-content-center is-align-content-center p-5" :style="$parent.$parent.mobileBackground">
    <div class="big-card has-text-centered">
      <div class="card-top pt-5">
        <div class="hourglass mt-5">
          <d-icon
            pack="fas"
            icon="hourglass-half"
            size="is-medium"/>
        </div>
        <div class="is-size-4 has-text-weight-bold">{{ $t('system.getReady')}}</div>
          <div
            v-if="$store.state.user.queuePlayers?.length == 0"
            class="is-size-5 pb-5">{{ $t('system.youAreNext')}}</div>
          <div v-else>
            <div class="pb-5">{{ $tc('system.peopleBeforeYout',$store.state.user.queuePlayers?.length)}} </div>
            <div class="players is-flex is-justify-content-center px-5 py-3">
              <div v-for="(player,index) in $store.state.user.queuePlayers" :key="player.dbid" class="player">
                <PlayerName
                  v-if="index < 3"
                  :class="{'thirds':$store.state.user.queuePlayers?.length >= 3}"
                  :player="player"/>
              </div>
              <div
                v-if="$store.state.user.queuePlayers?.length > 3"
                class="is-flex pt-2 more">
                  +{{$store.state.user.queuePlayers?.length-3}}
              </div>
            </div>
          </div>
        </div>
        <div class="card-bottom p-3">
        <div class="has-text-weight-bold mb-2">{{$t('system.estimatedTime')}}</div>
        <div class="qeuetimer">
          <b-tag class="pl-1 pr-2" size="is-small">
            <d-icon
              pack="fas"
              icon="clock"
              size="is-small"
              class="mx-1"/> {{$store.state.user.queueMinutes }}:00
          </b-tag>
            <div class="is-size-6">{{$t('system.toStart')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './PlayerQueue.scss';
export default {
  data() {
    return {}
  }
};
</script>